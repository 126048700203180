import React from "react"
import StandardLayout from "../../layouts/standard"
import about1 from "../../images/about/Group 16.jpg"
import about2 from "../../images/about/Group 20.jpg"
import about3 from "../../images/about/Group 24.jpg"
import about4 from "../../images/about/Group 30.png"
import about5 from "../../images/about/Group 60.png"
import about6 from "../../images/about/Group 61.png"
import "react-tabs/style/react-tabs.css"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { Link } from "gatsby"

export default function WhoAreWe(props) {
  return (
    <StandardLayout title="Who Are We?">
      <div className="aboutus-main">
        <div className="aboutus-heading">
          <div>
            <span className="public-head">
              Public Spaces,
              <br />
              Set Design,
              <br />
              Homes
            </span>
          </div>
          <div className="abs-about">
            <span className="aboutus-head">
              <strong>
                ABOUT
                <br />
                US
              </strong>
            </span>
          </div>
        </div>
        <div className="about-img-cont">
          <img style={{width:"100%"}} src={about1} alt="" />
        </div>
      </div>

      <div className="aboutus-main2">
        <div className="about2-img-cont">
          <img style={{width:"100%"}} className="about-img" src={about2} alt="" />
        </div>
        <div className="route-cont">
          <Tabs>
            <TabList>
              <Tab>WHO WE ARE?</Tab>
              <Tab>VISION</Tab>
              <Tab>MISSION</Tab>
            </TabList>
            <TabPanel>
              <p>
                <strong>Stylam Welcomes you to the World of Laminate.</strong>{" "}
                In 1991 under the name Golden Laminates Limited we started our
                operations and expanded into a global entity in 2001.The Brand
                name was set up in year 2010. Today our business is spread over
                80 countries with a majority of customers in Europe, North
                America, Russia, Middle East and Africa.
              </p>
              <p>
                With diverse business interests, we currently stand as the
                largest laminate producing group in India with a production
                capacity of over 15.5 million laminate sheets annually. We
                maintain high standards of research and development to offer an
                infinite product range. With an eye on evolving market demands
                and design trends, we are able to offer best products which
                gives us world class reputation. An excellent network of
                distributors and a successful global and local supply chain
                network has served us well for over 30 years in industry.
              </p>
            </TabPanel>
            <TabPanel>
              <p>
                To be a Global Entity in every arena of our work by Delivering
                value based solutions.
              </p>
            </TabPanel>
            <TabPanel>
              <p>
                We are a global family with proud Indian Heritage committed to
                provide innovative building material solutions around the World.
              </p>
            </TabPanel>
          </Tabs>
        </div>
      </div>

      <div className="timeline-main">
        <div className="knowmore">
          <span>KNOW MORE</span>
        </div>
        <div className="timeline-cont1">
          <div className="timeline-cont">
            <div className="timeline-img-text">
              <img className="timeline-img" src={about3} alt="" />
              <span style={{ fontWeight: "bold" }}>WHAT WE DO</span>
              <p style={{ fontSize: "12px" }}>
                We always strive to service our customers with the utmost
                integrity and to their complete satisfaction.
              </p>
              <img src={about4} alt="" />
              <p style={{ fontSize: "12px" }}>
                <strong>
                  30 years of working with ambition. With a vision in Mind. And
                  a passion in heart. And commitment in every step.
                </strong>
              </p>
              <p style={{ fontSize: "12px" }}>
                Stylam is proud to have completed 30 glorious years. A long
                successful journey that has just started.
              </p>
            </div>
          </div>
          <div className="timeline-cont" style={{ paddingTop: "120px" }}>
            <div className="timeline-cont2">
              <div style={{ textAlign: "center" }}>
                <span className="milestonee-text">milestones</span>
              </div>
              <div className="waterfall-cont">
                <div className="waterfall-item">
                  <div className="year-text">
                    <strong>1991</strong>
                  </div>

                  <div className="waterfall-item-text">
                    <Circle />
                    Golden Laminates Limited starts operation as Private Limited
                    Company.
                  </div>
                </div>
                <div className="waterfall-item">
                  <div className="year-text">
                    <strong>1995</strong>
                  </div>

                  <div className="waterfall-item-text">
                    <Circle />
                    1995 within four years listed in Bombay Stock Exchange.
                  </div>
                </div>
                <div className="waterfall-item">
                  <div className="year-text">
                    <strong>1996</strong>
                  </div>

                  <div className="waterfall-item-text">
                    <Circle />
                    Recognised as 'Export House' by Government of India.
                  </div>
                </div>

                <div className="waterfall-item">
                  <div className="year-text">
                    <strong>2001</strong>
                  </div>

                  <div className="waterfall-item-text">
                    <Circle />
                    Expanded into a global entity.
                  </div>
                </div>

                <div className="waterfall-item">
                  <div className="year-text">
                    <strong>2010</strong>
                  </div>

                  <div className="waterfall-item-text">
                    <Circle />
                    Named Stylam Industries Limited
                  </div>
                </div>

                <div className="waterfall-item">
                  <div className="year-text">
                    <strong>2012</strong>
                  </div>

                  <div className="waterfall-item-text">
                    <Circle />
                    First ever Indian manfacturing company to introduce HPL
                    Exterior cladding with brand name 'Fascia'.
                  </div>
                </div>
                <div className="waterfall-item">
                  <div className="year-text">
                    <strong>2013</strong>
                  </div>

                  <div className="waterfall-item-text">
                    <Circle />
                    Introduces it's premium sub brand 'VIOLAM' Fascia HPL
                    Exterior Cladding.
                  </div>
                </div>
                <div className="waterfall-item">
                  <div className="year-text">
                    <strong>2017</strong>
                  </div>

                  <div className="waterfall-item-text">
                    <Circle />
                    Stylam launched range of Restroom Cubicles & Lockers.
                  </div>
                </div>

                <div className="waterfall-item">
                  <div className="year-text">
                    <strong>2018</strong>
                  </div>

                  <div className="waterfall-item-text">
                    <Circle />
                    Introducing and adding new product line of Acrylic Solid
                    Surfaces (India's first manufacturer)
                  </div>
                </div>

                <div className="waterfall-item">
                  <div className="year-text">
                    <strong>2019</strong>
                  </div>

                  <div className="waterfall-item-text">
                    <Circle />
                    Stylam introduced world's first Hot Coating Press Machine.
                  </div>
                </div>

                <div className="waterfall-item">
                  <div className="year-text">
                    <strong>2020</strong>
                  </div>

                  <div className="waterfall-item-text">
                    <Circle />
                    By June'20, Stylam introduced Short Cycle Press Technique
                    for making PreLam Board in both Anti Finger and High Gloss
                    finishes.
                  </div>
                </div>

                <div className="waterfall-item">
                  <div className="year-text">
                    <strong>2021</strong>
                  </div>

                  <div className="waterfall-item-text">
                    <Circle />
                    Stylam working on overall design, development and
                    introduction of new innovative product ranges for building
                    material industry.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="morelink-cont">
        <div className="morelink-img">
          <div>
            <strong>
              <Link to="/about/infrastructure">Infrastructure</Link>
            </strong>
          </div>

          <img src={about5} alt="" />
        </div>
        <div className="morelink-img">
          <div>
            <strong>
              <Link to="/about/our-core-values">Our Core Values</Link>
            </strong>
          </div>

          <img src={about6} alt="" />
        </div>
      </div>
    </StandardLayout>
  )
}

function Circle() {
  return (
    <div
      style={{
        border: "1px solid black",
        borderRadius: "50%",
        width: "21px",
        height: "21px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: "-11px",
        top: "-5px",
      }}
    >
      <div
        style={{
          border: "1px solid #AB1C24",
          borderRadius: "50%",
          width: "11px",
          height: "11px",
          backgroundColor: "#AB1C24",
        }}
      ></div>
    </div>
  )
}
